//

@mixin font-sans {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

@mixin font-serif {
    font-family: "Lora", serif;
    font-weight: 400;
}

//
