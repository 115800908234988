@import "./styles/common.scss";

html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100%;

    // Cause of the triple scroll bar in edge
    // overflow: scroll;

    background-color: #ADD8E6;
}

a {
    text-decoration: none;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100%;

    overflow: scroll;
    overflow-x: hidden;

}

// @font-face {
//     font-family: "Open Sans";
//     src: url(/font/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf);
//     //
//     font-weight: 100 900; // range of variable weights from 100 to 900
//     font-display: swap; // ?
// }
//
// @font-face {
//     font-family: "Open Sans";
//     src: url(/font/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf);
//     font-style: italic;
//     //
//     font-weight: 100 900; // range of variable weights from 100 to 900
//     font-display: swap; // ?
// }
//
// //
//
// @font-face {
//     font-family: "Lora";
//     src: url(/font/Lora/Lora-VariableFont_wght.ttf);
//     //
//     font-weight: 100 900; // range of variable weights from 100 to 900
//     font-display: swap; // ?
// }
//
// @font-face {
//     font-family: "Lora";
//     src: url(/font/Lora/Lora-Italic-VariableFont_wght.ttf);
//     font-style: italic;
//     //
//     font-weight: 100 900; // range of variable weights from 100 to 900
//     font-display: swap; // ?
// }

//
//
