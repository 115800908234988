@import "./styles/common.scss";
//

$page_width: 1200px;

$featured_image_intro_duration: 0.48s;

$logotype_intro_delay: 0.36s;
$logotype_intro_duration: 0.48s;
$logotype_intro_scale_duration: 0.84s;

$header_links_intro_delay: -0.12s;
$header_links_intro_item_delay: 0.12s;

$header_links_intro_delay_TOTAL: $logotype_intro_delay + $logotype_intro_duration + $header_links_intro_delay;

//

.home {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    // height: auto;
    // min-height: 100%;

    // background-color: red;

    // @include font-sans;
    @include font-serif;
}

//

.mobile-menu-button-wrap {
    // display: flex;
    display: none;
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // width: calc(100% - 48px);
    width: 100%;
    height: 54px;
}

.mobile-menu-button {
    z-index: 221;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    right: 0px;
    cursor: pointer;

    padding: 16px 16px;
}

.mobile-menu {
    z-index: 5;
    // display: flex;
    display: none;
    position: fixed;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    overflow: scroll;

    background-color: #ADD8E6;

    opacity: 0.0;
    transition: opacity 0.21s ease-in-out;

    &._exists {
        display: flex;
    }
    &._visible {
        opacity: 1.0;
    }
}

.mobile-menu-link-set {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;

    padding: 32px 0px;
}

.mobile-menu-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 36px;

    @include font-sans;
    font-size: 16px;
    font-weight: 540;
    color: rgba(0, 0, 1, 0.72);

    transition: color 0.12s ease-in-out;

    .mobile-menu-link-text {
        display: inline;
    }

    &:hover {
        color: rgba(0, 0, 1, 1.0);
    }
}

.hamburger {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 21px;
    height: 21px;

    .hamburger-line {
        display: flex;
        position: relative;
        width: 100%;
        height: 2px;

        background-color: rgba(0, 0, 1, 1.0);

        transition: transform 0.32s ease-in-out;

        transform-origin: center center;
    }

    &.open {
        .line-01 {
            transform: translate(0px, 7px) rotate(45deg);
        }
        .line-02 {
            transform: scale(0.0, 1.0);
        }
        .line-03 {
            transform: translate(0px, -7px) rotate(-45deg);
        }
    }
}

//

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    //

    // background-color: #ffffff;
}

.header-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.header-links-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 100%;
    width: calc(100% - 96px);
    max-width: $page_width;
    height: auto;

    // border-top: solid 2px #f3f3f4;
    border-top: solid 2px rgba(0, 0, 5, 0.08);
}

.header-links-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $page_width;
    height: 44px;
    //
}

.header-link-item {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    // height: calc(100% - (5px * 2)); // == 100% with padding
    // padding: 5px 24px #{5px + 2px} 24px;
    padding: 0px 24px;

    opacity: 0.0;
    transition: opacity 0.48s ease-in-out;

    .header-link-text {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 100%;
        cursor: pointer;

        @include font-sans;
        font-weight: 590;
        font-size: 13px;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: rgba(0, 0, 1, 0.64);

        // transition: color 0.12s ease-in-out;

        &::after {
            content: "";
            display: flex;
            position: absolute;
            width: 100%;
            height: 2px;

            top: -2px;
            background-color: rgba(0, 0, 1, 1.0);

            opacity: 0.0;
            transform: scale(0.0, 1.0);
            transform-origin: left center;

            transition: transform 0.48s ease-in-out,
                opacity 0.48s ease-in-out;
        }
    }

    .header-link-dropdown {
        z-index: 100;
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        top: 100%;
        left: 0px;

        visibility: hidden;
        opacity: 0.0;

        transition: opacity 0.16s ease-in-out;

        background-color: #ADD8E6;
    }

    &:hover {
        color: rgba(0, 0, 1, 1.0);

        .header-link-text {
            &::after {
                opacity: 1.0;
                transform: scale(1.0, 1.0);
                transition: transform 0.16s ease-in-out,
                    opacity 0.0s ease-in-out;
            }
        }

        .header-link-dropdown {
            visibility: visible;
            opacity: 1.0;

            .header-link-text {
                &::after {
                    display: none;
                }
            }
            .header-link-dropdown-item {
                display: flex;
                position: relative;
                height: 44px;
                width: calc(100% - 48px);
                padding: 0px 24px;
                white-space: nowrap;

                &::after {
                    content: "";
                    display: flex;
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    left: 0px;
                    top: 0px;

                    background-color: rgba(0, 0, 1, 1.0);
                    opacity: 0.0;
                    transition: opacity 0.16s ease-in-out;
                }

                &:hover {
                    &::after {
                        opacity: 1.0;
                    }
                }
            }
        }
    }

    //
    // This is the transition intro one at a time on page load
    // &:nth-child(1) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 1);
    // }
    // &:nth-child(2) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 2);
    // }
    // &:nth-child(3) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 3);
    // }&:nth-child(4) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 4);
    // }&:nth-child(5) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 5);
    // }&:nth-child(6) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 6);
    // }&:nth-child(7) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 7);
    // }&:nth-child(8) {
    //     transition-delay: $header_links_intro_delay_TOTAL + ($header_links_intro_item_delay * 8);
    // }
}

.logotype {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 92px;

    @include font-sans;
    font-size: 21px;
    font-weight: 640;
    line-height: 1.2em;

    opacity: 0.0;
    transition: opacity $logotype_intro_duration ease-in-out $logotype_intro_delay;

    .line-01, .line-02 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;

        text-align: center;

        &.line-01 {
            font-size: 1.0em;
            text-transform: uppercase;
        }
        &.line-02 {
            font-size: 0.72em;
        }
    }
}

.featured {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100%;
    max-height: 800px;
    max-width: $page_width;
    //

    width: calc(100% - 96px);
    margin: 0px 48px 0px 48px;

    overflow: hidden;
}

.featured-image {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    transform: scale(1.3);
    opacity: 0.0;

    transition: opacity $featured_image_intro_duration ease-out,
        transform $logotype_intro_scale_duration cubic-bezier(0.33, 1, 0.68, 1); // ease-out-cubic
}

.featured-image-inner {
    display: flex;
    width: auto;
    height: auto;
    border-radius: 1rem;
    // min-height: 570px;
    height: 570px;
    max-width: 1020px;
    padding: 6px 6px;

}

.icon-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    //
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 32px;
    width: 96px;
    // height: 32px;
    padding: 8px 0px;

    cursor: pointer;

    color: rgba(0, 0, 1, 0.72);

    transition: color 0.16s ease-in-out;

    .icon-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        font-size: 32px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .icon-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 32px;

        margin-top: 4px;

        @include font-sans;
        font-size: 12px;
        font-weight: 480;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        line-height: 1.2em;

        opacity: 0.0;
        transition: opacity 0.16s ease-in-out;
    }

    &:hover {
        color: rgba(0, 0, 1, 1.0);

        .icon-title {
            opacity: 1.0;
        }
    }
}

//

.search-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 24px);
    // max-width: 1200px;
    max-width: 560px;

    margin: 36px auto 24px auto;
}

//

.footer-divider {
    display: flex;
    width: 80px;
    height: 1px;

    margin: 24px auto 24px auto;

    background-color: rgba(0, 0, 1, 0.48);
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;

    @include font-sans;
    font-size: 12px;
    font-weight: 480;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-link {
    text-decoration: none;
    color: #000080;

    &:hover {
        color: #ff0000;
    }
}

//
//

.home._visible {
    //

    .logotype {
        opacity: 1.0;
    }
    .header-link-item {
        opacity: 1.0;
    }
    .featured-image {
        opacity: 1.0;
        transform: scale(1.02);
    }
}

#news-area {
    text-align: center;
    max-height: 500px;
    width: 90%;
    max-width: 1020px;
    margin-bottom: 70px;
    @include font-sans;
    
}
#new-area > h6, h5, h4, h3, h2, h1 {
    text-transform: uppercase;
}
#news-items-area {
    width: 100%;
    border: 2px solid rgba(0, 0, 1, 0.72);
    border-radius: 5px;
    max-height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}
.events-header {
    font-weight: 600;
}
.news-text-container{
    color: black;
}
.news-text-header {
    margin-top: 10px;
    margin-bottom: 2px;
}
.news-text-item {
    text-decoration: none;
    color: black;
    margin-top: 2px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.events-image {
    max-width: 5rem;
    max-height: 5rem;
    margin: 1rem 2rem;
    flex-grow: 0;

}
.events-details {
    flex-grow: 0;
    padding-right: 1.5rem;
}
.events-anchor {
    text-decoration: none;
    color: black;
    border-top: .5px solid whitesmoke;
    width: auto;
    display: grid;
    grid-template-columns: auto 8fr;
    justify-content: space-around;
    margin-bottom: 5px;
}
.events-header {
    margin-bottom: .75rem;
}
.photos-image{
    max-width: 100%;
}
#news-spacer {
    height: 50px;
}
.item-headers {
    margin-top: 10px;
    padding-top: 15px;
    border-top: .5px solid whitesmoke;
    // border-bottom: .5px solid whitesmoke;
}
.news-anchor {
    display: flex;
    justify-content: space-around;
}
.news-item {
    border-top: .5px solid whitesmoke;
}
.photo-item {
    border-top: .5px solid whitesmoke;
    padding-top: 15px;
    padding-bottom: 10px;
}

//
//

@media (max-width: 1200px) {
    .header-inner {
        width: 50%;

    }
}

@media (max-width: 960px) {
    .featured {
        width: calc(100% - 48px);
        margin: 0px 24px 0px 24px;
    }
    //
    .header {
        margin-top: 24px;
    }
    .featured-image-inner {
        width: 400px;
        height: 200px;
    }
    .logotype {
        font-size: 18px;
    }
    .header-links-row {
        display: none;
    }
    .mobile-menu-button-wrap {
        display: flex;
    }
    // .header-links-set {
    //     flex-direction: column;
    //     height: auto;
    // }
    .search-wrap {
        margin: 24px auto 18px auto;
    }
}

@media (max-width: 480px) {
    .mobile-menu-button-wrap {
        display: flex;
    }
    .logotype {
        .line-01, .line-02 {
            &.line-01 {
                font-size: 0.8em;
            }
        }
    }
    .featured-image-inner {
        width: 350px;
        height: 190px;
    }
    .icon {
        width: 72px;

        .icon-icon {
            width: 21px;
            height: 21px;
            font-weight: 21px;
        }
        .icon-title {
            font-size: 10px;
            font-weight: 540;
        }
    }
    .footer {
        font-size: 10px;
        font-weight: 540;
    }
}

//
//
