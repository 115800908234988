@import "../styles/common.scss";
//

$search_height: 36px;
$search_height_half: $search_height * 0.5;

.Search_Box {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
}

.search_input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // width: 100%;
    width: calc(100% - #{$search_height * 1.25 + $search_height + 2px});
    height: $search_height;
    border-radius: $search_height * 0.5;

    outline: none;
    border: solid 1px rgba(0, 0, 1, 0.27);
    background-color: rgba(254, 254, 255, 0.72);

    transition: background-color 0.16s ease-in-out,
        border-color 0.16s ease-in-out;

    margin: 0px auto;
    padding: 0px #{$search_height * 1.25} 0px #{$search_height + 2px};
}

.search_icon {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: $search_height;
    height: $search_height;
    // top: 0px;
    left: 2px;

    font-size: $search_height * 0.44;

    opacity: 0.84;

    transition: opacity 0.16s ease-in-out;

    svg {
        width: $search_height * 0.44;
        height: $search_height * 0.44;
    }
}

.search_button {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: $search_height * 1.25;
    height: $search_height;
    // top: 0px;
    right: 0px;
    cursor: pointer;

    border: none;
    outline: none;

    border-radius: 0px $search_height_half $search_height_half 0px;

    background-color: #1a73e8;
    color: rgba(254, 254, 255, 1.0);

    font-size: $search_height * 0.44;

    svg {
        width: $search_height * 0.44;
        height: $search_height * 0.44;
    }
}

//

.Search_Box._focused {
    .search_input {
        background-color: rgba(254, 254, 255, 1.0);
        border-color: rgba(0, 0, 1, 0.56);
    }
    .search_icon {
        opacity: 1.0;
    }
}

//
//
